<script setup>
import { ref,onUpdated, defineProps } from 'vue';

import moment from 'moment';

const props = defineProps(["contribuicao"]);

const contribuicao = ref(props.contribuicao);

onUpdated(() => {
  contribuicao.value = props.contribuicao
})
</script>

<template>
  <Chip label="Pago" style="background: green;" v-if="contribuicao.DATPAGCNT" />
  <Chip label="Vencida" style="background: red;" v-else-if="contribuicao.DATPAGCNT === null && (moment(Date.now()).format('YYYYMMDD') > moment(contribuicao.DATVENCNT).format('YYYYMMDD'))" />
  <Chip label="Cancelada" style="background: red;" v-else-if="contribuicao.DATCANCNT" />
  <Chip label="Enviada" style="background: lightblue;" v-else-if="contribuicao.DATENVCNT" />
  <Chip label="Aberta" style="background: lightsalmon;" v-else />  
</template>